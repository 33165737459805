import type ICandidate from '../types/ICandidate';
import type { IElectionGroup } from '../types/IElectionGroup';
import appConfig from 'src/config/app.config.json';

export const createUrl = (url: string) => new URL(url, process.env.REACT_APP_BACKEND_API_URL).href;


/**
 * Pathify candidate, eg. Al Ben, number 4 -> al-ben-4
 * @param candidate Candidate
 * @returns Path segment
 */
export function candidateToPathSegment(candidate: ICandidate): string {
  return `${candidate.candidate_calling_name}-${candidate.candidate_last_name}-${candidate.candidate_number}`;
}

/**
 * Pathify electionGroup, eg. OAJ-YSI Pääkaupungin vaalipiiri -> oaj-ysi-pääkaupungin-vaalipiiri
 * @param electionGroup ElectionGroup
 * @returns Path segment
 */
export function electionGroupToPathSegment(electionGroup: IElectionGroup): string {
  return pathify(electionGroup.elec_group_name);
}

function pathify(name: string): string {
  return name
    .toLowerCase()
    .replace(/ä/g, "a")
    .replace(/ö/g, "o")
    .split(' ')
    .filter((n) => n.length > 0)
    .map(encodeURIComponent)
    .join('-');
}

/**
 * Find electionGroup based on path segment
 * @param pathSegment A pathified electionGroup name, eg. oaj-ysi-pääkaupunkiseutu
 * @param electionGroups List of electionGroups
 * @returns ElectionGroup or null
 */
export function findElectionGroupByPathifiedString(
  pathSegment: string,
  electionGroups: IElectionGroup[] | undefined,
): IElectionGroup | null {
  return electionGroups?.find((p) => pathify(p.elec_group_name) === pathSegment) ?? null;
}

/**
 * Find candidate based on path segment
 * @param pathSegment A pathified candidate name and electorial number, eg. foo-bar-49
 * @return Candidate or null
 */
export function findCandidateByPathifiedString(
  pathSegment: string,
  candidates: ICandidate[] | undefined,
): ICandidate | null {
  // TODO destructure candidate path
  return candidates?.find((c) => pathSegment === candidateToPathSegment(c)) ?? null;
}
