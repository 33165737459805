import { useTheme } from '@material-ui/core'
import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Device } from 'src/common/utilities/devices';
import { getCurrentPrimaryColor } from 'src/common/utilities/themeHelper';
import styled from 'styled-components';

import Padding from '../../common/components/Padding';
import { useElectionInformation } from '../../common/hooks/useElectionInformation';

export default function ElectionBanner() {
  const match = useRouteMatch<{uuid:string, electionGroup: string; }>();
  const { data: electionInformation } = useElectionInformation(match.params.uuid);
  const theme = useTheme();
 
  const desc = electionInformation?.elec_election_descr || '';
  const descriptions = (desc).split('\n').map((d, i) => {
    return <div>{d}</div>
  });

  return (
    <Container className="election-banner" style={{ backgroundColor: getCurrentPrimaryColor(theme) }}>
      <section>
        <header>{electionInformation?.elec_election_name}</header>
        <div className="subtitle">{descriptions}</div>
      </section>
    </Container>
  );
}

const Container = styled(Padding)`
  width: 100%;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%),
    0px 1px 10px 0px rgb(0 0 0 / 12%);

  font-weight: lighter;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  padding-top: 0.8rem;
  padding-bottom: 0.8rem;

  header {
    font-size: 2rem;
    margin-bottom: 0.3rem;
  }

  .subtitle {
    font-size: 1.2rem;
  }

  @media ${Device.Tablet} {
    padding-top: 2rem;
    padding-bottom: 2rem;
    header {
      font-size: 3rem;
    }

    .subtitle {
      font-size: 1.4rem;
    }
  }

  @media ${Device.Laptop} {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    header {
      font-size: 5rem;
    }

    .subtitle {
      font-size: 1.75rem;
    }
  }
`;
