import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useConfiguration } from '../../common/hooks/useConfiguration';
import { useRouteMatch } from 'react-router-dom';
import { Device } from 'src/common/utilities/devices';

const Container = styled.div`
  margin-right: auto;
  width: auto;

  @media ${Device.MobileS} {
    a img {
      margin-top: 10px;
      height: 3em;
    }
  }

  @media ${Device.Laptop} {
    height: 80%;
    margin-left: -30px;

    a {
      height: 100%;
    }

    a img {
      margin-top: 0px;
      height: 100%;
    }
  }

  
`;

export default function TenantLogoLink() {
  const { t } = useTranslation();
  const match = useRouteMatch<{uuid:string, electionGroup: string; }>();
  const { data: config } = useConfiguration(match.params.uuid);

  return (
    <Container>
      <a href={config?.ExternalUrl}>
        <img src={config?.LogoUrl } alt={t('candidategallery.tenantlogo.alt')} />
      </a>
    </Container>
  );
}
