import styled from 'styled-components';
import { Device } from '../utilities/devices';

export const IfMobile = styled.div`
  @media ${Device.MobileS} {
    display: block;
  }

  @media ${Device.Tablet} {
    display: none;
  }

  @media ${Device.Laptop} {
    display: none;
  }
`;

export const IfTablet = styled.div`
  @media ${Device.MobileS} {
    display: none;
  }

  @media ${Device.Tablet} {
    display: block;
  }

  @media ${Device.Laptop} {
    display: none;
  }
`;

export const IfDesktop = styled.div`
  @media ${Device.MobileS} {
    display: none;
  }

  @media ${Device.Tablet} {
    display: none;
  }

  @media ${Device.Laptop} {
    display: block;
  }
`;

export const IfMobileOrTablet = styled.div`
  @media ${Device.MobileS} {
    display: block;
  }

  @media ${Device.Tablet} {
    display: block;
  }

  @media ${Device.Laptop} {
    display: none;
  }
`;

export const IfMobileOrDesktop = styled.div`
  @media ${Device.MobileS} {
    display: block;
  }

  @media ${Device.Tablet} {
    display: none;
  }

  @media ${Device.Laptop} {
    display: block;
  }
`;
