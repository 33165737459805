import React from 'react';
import styled from 'styled-components';
import LaunchIcon from '@material-ui/icons/Launch';
import { useElectionInformation } from '../hooks/useElectionInformation';
import { useParams } from 'react-router-dom';
import { useTheme } from '@material-ui/core'
import { getCurrentPrimaryColor } from '../utilities/themeHelper';

const Container = styled.a`
  font-weight: 700;
  text-align: center;
  display: flex;
  gap: 0.3em;
  text-decoration: none;
`;

export default function ElectionExternalLink() {
  const params = useParams<{uuid:string, electionGroup: string; }>();
  const theme = useTheme();
  const { data: electionInformation } = useElectionInformation(params.uuid);

  return (
    <>
    { electionInformation?.external_url ?
      <Container style={{color: getCurrentPrimaryColor(theme)}} className="election-external-link" href={electionInformation?.external_url} target="_blank">
        {electionInformation?.elec_election_name?.toUpperCase()}
        <LaunchIcon />
      </Container> : null
    }
    </>
  );
}