import { useQuery } from 'react-query';
import Queries from './queries';
import { parseISO } from 'date-fns';
import { createUrl } from '../utilities/urlHelpers';
import type { IElectionInformation, IElectionInformationResponse } from '../types/IElectionInformation';

async function GetElectionInformation(id: string): Promise<IElectionInformation> {
  if ( id ){

    const result = await fetch(createUrl(`/elections/${id}`));
    const data: IElectionInformationResponse = await result.json();
    return {...data, 
      result_publication_date: parseDate(data.result_publication_date),
      external_url : data.external_url ? withHttps(data.external_url) : undefined
    }
  }

  let x: IElectionInformation = {id:-1, econtact_list:[], show_gallery_yn:'N'};
  return x;
}

const parseDate = (d: string | undefined): Date | undefined => d ? parseISO(d) : undefined;

const withHttps = (url: string) => url.replace(/^(?:(.*:)?\/\/)?(.*)/i, (match, schemma, nonSchemmaUrl) => schemma ? match : `https://${nonSchemmaUrl}`);

export function useElectionInformation(id: string) {
  return useQuery([Queries.ElectionInformation, id], () => GetElectionInformation(id), {
    //enabled: !!id
  });
}