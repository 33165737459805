import type ICandidate from '../../common/types/ICandidate';

export default function Cities(
  candidates: ICandidate[] | undefined,
): string[] {
  if (!candidates) {
    return [];
  }

  // Remove also duplicates (we use filter for that)
  return candidates.map(c => c.locality_text).filter((v,i,a) => a.indexOf(v) == i).sort();
}