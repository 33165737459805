import { CircularProgress, createMuiTheme, PaletteType, ThemeProvider } from '@material-ui/core';
import { useEffect, useState, useMemo, createContext } from 'react';
import NoMatch from 'src/features/NoMatch';
import { useConfiguration } from '../hooks/useConfiguration';
import type { IClientConfiguration } from '../types/IClientConfiguration';
import styled from 'styled-components';

let theme = createMuiTheme({
  });  

interface ClientThemeProviderProps {
  uuid? : string,
  hasLoadedInitData : boolean
}

export const ThemeContext = createContext({ changeThemeType: () => {} });

export const ClientThemeProvider:  React.FC<ClientThemeProviderProps> = ({children, uuid, hasLoadedInitData}) => {

  const { data } = useConfiguration(uuid);
  const [counter, setCounter] = useState(0);
  const [themeType, setThemeType] = useState<PaletteType>('light');
  
  const themeTypeMode = useMemo(
    () => ({
      changeThemeType: () => {
        setThemeType((prevType) => (prevType === 'light' ? 'dark' : 'light'));
      },
    }),
    [],
  );

  const getTheme = (type : PaletteType, config : IClientConfiguration) => ({
    palette : {
      type,
      ...(type === 'light' ?
      {
        primary: {
          // main has to be set although we need to set light ....
          main: config.PrimaryColor,
          light: config.PrimaryColor,
        },
        secondary: {
          // main has to be set although we need to set light ....
          main: config.SecondaryColor,
          light: config.SecondaryColor
        },
      }
      : 
      {
        // NOTE! Dark mode does not work as expected because we are missing correct colors ...
        // So this is not usefull yet...
        primary: {
          // main has to be set although we need to set light ....
          main: config.PrimaryColor,
          dark: config.PrimaryColor,
        },
        secondary: {
          // main has to be set although we need to set light ....
          main: config.SecondaryColor,
          dark: config.SecondaryColor
        },
      })
    }    
  });

  useEffect(() => {

    if ( data?.LogoUrl ){
      theme = createMuiTheme(getTheme(themeType, data));
      setCounter(c => c+1);
    }
  }, [data, themeType]);

  return (
    <ThemeContext.Provider value={themeTypeMode}>
      <ThemeProvider key={counter} theme={theme}>
        {
          hasLoadedInitData ? 
          (
          data?.ElectionId ? 
            children : 
            <NoMatch />)
          : (
            <ProgressInfoContainer>
              <CircularProgress />
            </ProgressInfoContainer>
          )
          }
      </ThemeProvider>
    </ThemeContext.Provider>
    );
} 

export const themes = ['dark', 'light'];

const ProgressInfoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
`;