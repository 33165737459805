import React from 'react';
import Slider from '@material-ui/core/Slider';
import styled from 'styled-components';

interface Props {
  value: [number, number];
  onChange: (minAge: number, maxAge: number) => void;
}

export default function AgeRangeSlider({ value, onChange }: Props) {
  const handleChange = (_: any, value: number | number[]) => {
    if (Array.isArray(value) && value.length > 1) {
      onChange(value[0], value[1]);
    }
  };
  return (
    <Container className="filtering-age-range-slider">
      <ValueBox>{value[0]}</ValueBox>
      <WrappedSlider
        defaultValue={[0, 100]}
        onChange={handleChange}
        valueLabelDisplay="off"
        aria-labelledby="range-slider"
      />
      <ValueBox>{value[1]}</ValueBox>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
`;

const ValueBox = styled.div`
  border: 1px solid #dfdfdf;
  border-radius: 3px;
  padding: 0.5rem;
  width: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  &:first-of-type {
    margin-right: 1rem;
  }

  &:last-of-type {
    margin-left: 1.6rem;
  }
`;

const WrappedSlider = styled(Slider)`
  .MuiSlider-thumb {
    margin-top: -8px;
    width: 24px;
    height: 24px;
  }

  .MuiSlider-track {
    height: 5px;
  }

  .MuiSlider-rail {
    height: 6px;
    border-radius: 5px;
  }
`;
