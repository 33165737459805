import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import debounce from 'lodash.debounce';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import type ICandidate from 'src/common/types/ICandidate';
import type ICandidateFilters from 'src/common/types/ICandidateFilters';
import type IElectionAllianceUnion from 'src/common/types/IElectionAllianceUnion';
import type { IElectionGroup } from 'src/common/types/IElectionGroup';
import {
  IfDesktop,
  IfMobileOrDesktop,
  IfMobileOrTablet,
  IfTablet,
} from 'src/common/components/DeviceSizeFilters';
import { Device } from 'src/common/utilities/devices';
import Routes from 'src/routes';
import styled from 'styled-components';
import Padding from '../../common/components/Padding';
import {
  findElectionGroupByPathifiedString,
  electionGroupToPathSegment,
} from '../../common/utilities/urlHelpers';
import CandidateFilter from './CandidateFilter';
import CandidateGrid from './CandidateGrid';
import FilterCandidates from './filtering';
import ElectionGroupCard from './ElectionGroupCard';
import ElectionGroupPicker from './ElectionGroupPicker';
import { useElectionGroups } from 'src/common/hooks/useElectionGroups';
import { useCandidates } from 'src/common/hooks/useCandidates';
import { useTheme } from '@material-ui/core'
import { getCurrentPrimaryColor } from 'src/common/utilities/themeHelper';
import Cities from './cities';

export default function ElectionGroup() {
  const { t } = useTranslation();
  const theme = useTheme();

  const params = useParams<{uuid:string, electionGroup: string; }>();

  // Pick a electionGroup in order to see electionGroup info, filters and candidates
  const [selectedElectionGroup, setSelectedElectionGroup] = useState<IElectionGroup | null>(null);
  const { isLoading: isLoadingElectionGroups, data: electionGroups } = useElectionGroups(params.uuid);

  // Use filters to filter candidates list
  const [filters, setFilters] = useState<ICandidateFilters>({
    searchTerm: null,
    city: null,
    allianceUnion: null,
    minAge: 0,
    maxAge: 100,
  });
  const setSearchTerm = (searchTerm: string | null) => setFilters({ ...filters, searchTerm });
  const setCity = (city: string | null) => setFilters({ ...filters, city });
  const setAllianceUnion = (allianceUnion: IElectionAllianceUnion | null) =>
    setFilters({ ...filters, allianceUnion });
  const setAgeRangeDebounced = useRef(
    debounce((minAge: number, maxAge: number) => setFilters({ ...filters, minAge, maxAge }), 10),
  ).current;

  // Fetch candidates for the candidates list
  const { isLoading: isLoadingCandidates, data: candidates } = useCandidates(selectedElectionGroup, params.uuid);

  // Re-calculate filtered candidates when filters change
  const [filteredCandidates, setFilteredCandidates] = useState<ICandidate[]>([]);
  useEffect(() => {
    setFilteredCandidates(FilterCandidates(filters, candidates));
  }, [candidates, filters]);

  const [cities, setCities] = useState<string[]>([]);
  useEffect(() => {
    setCities(Cities(candidates));
  }, [candidates]);

  // Routing
  // Set selected electionGroup based on url path, eg. /<lang>/some-electionGroup
//  const params: { electionGroup: string } = useParams();

  useEffect(() => {
    if (params.electionGroup && electionGroups) {
      setSelectedElectionGroup(findElectionGroupByPathifiedString(params.electionGroup, electionGroups));
    }
  }, [params.electionGroup, electionGroups]);

  // Routing
  // Set url according to selected electionGroup, eg. pick electionGroup "Some ElectionGroup" -> /<lang>/some-electionGroup
  const history = useHistory();
  const match = useRouteMatch<{ language: string; electionGroup: string, uuid:string }>();
  const handleSelectElectionGroup = (electionGroup: IElectionGroup | null) => {
    if (!electionGroup) {
      // User has deselected electionGroup
      history.replace(generatePath(Routes.Home, { uuid: match.params.uuid, language: match.params.language }));
      setSelectedElectionGroup(null);
    } else {
      const newPath = generatePath(Routes.ElectionGroup, {
        uuid: match.params.uuid,
        language: match.params.language,
        electionGroup: electionGroupToPathSegment(electionGroup),
      });
      history.replace(newPath);
      setSelectedElectionGroup(electionGroup);
    }
  };

  return (
    <>
      <Grid container>
        <Container>
        <Grid item container xs={12} justify="flex-end" alignItems="flex-start">
          <Grid item xs={12} md={6} lg={4} container direction="column">
            <Grid item xs={12}>
              <ElectionGroupPicker
                isLoading={isLoadingElectionGroups}
                electionGroups={electionGroups}
                onSelect={handleSelectElectionGroup}
                value={selectedElectionGroup}
              />
            </Grid>
            <IfTablet>
              {!isLoadingElectionGroups && selectedElectionGroup && (
                <TabletFiltersContainer>
                  <Grid item xs={12}>
                    <CandidateFilter
                      onAgeRangeChanged={setAgeRangeDebounced}
                      onCitySelected={setCity}
                      onAllianceUnionSelected={setAllianceUnion}
                      onSearchTermChanged={setSearchTerm}
                      ageRange={[filters.minAge, filters.maxAge]}
                      selectedElectionGroup={selectedElectionGroup}
                      cities={cities}
                    />
                  </Grid>
                </TabletFiltersContainer>
              )}
            </IfTablet>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            {!isLoadingElectionGroups && selectedElectionGroup && (
              <ElectionGroupCard electionGroup={selectedElectionGroup} />
            )}
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            {!isLoadingElectionGroups && selectedElectionGroup && (
              <IfMobileOrDesktop>
                <Grid container direction="column" spacing={2}>
                  <Grid item xs={12}>
                    <CandidateFilter
                      onAgeRangeChanged={setAgeRangeDebounced}
                      onCitySelected={setCity}
                      onAllianceUnionSelected={setAllianceUnion}
                      onSearchTermChanged={setSearchTerm}
                      ageRange={[filters.minAge, filters.maxAge]}
                      selectedElectionGroup={selectedElectionGroup}
                      cities={cities}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <IfDesktop>
                      <FilteredCandidatesCountLabelDesktop>
                        {t('candidategallery.filters.results_label_start')}
                        <strong>{filteredCandidates.length}</strong>
                        {t('candidategallery.filters.results_label_end', {
                          count: filteredCandidates.length,
                        })}
                      </FilteredCandidatesCountLabelDesktop>
                    </IfDesktop>
                  </Grid>
                </Grid>
              </IfMobileOrDesktop>
            )}
          </Grid>
        </Grid>
        
        {!isLoadingElectionGroups && selectedElectionGroup && (
          <Grid item xs={12}>
            <IfMobileOrTablet>
              <FilteredCandidatesCountLabelMobileOrTable className="filtered-candidates-count-mobile"  style={{ backgroundColor: getCurrentPrimaryColor(theme) }}>
                {t('candidategallery.filters.results_label_start')}
                {filteredCandidates.length}
                {t('candidategallery.filters.results_label_end', { count: filteredCandidates.length })}
              </FilteredCandidatesCountLabelMobileOrTable>
            </IfMobileOrTablet>
          </Grid>
        )}
        </Container>
        { selectedElectionGroup && 
          <CandidateGridContainer>
        <Grid item xs={12}>
          {selectedElectionGroup && isLoadingCandidates && (
            <LoaderContainer>
              <CircularProgress />
            </LoaderContainer>
          )}
          {selectedElectionGroup && candidates && <CandidateGrid candidates={filteredCandidates} />}
        </Grid>
        </CandidateGridContainer>
        }
      </Grid>
    </>
  );
}

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

const TabletFiltersContainer = styled.div`
  padding-top: 50px;
`;

const FilteredCandidatesCountLabelDesktop = styled.div`
  float: right;
  font-size: 1.1em;
`;

const FilteredCandidatesCountLabelMobileOrTable = styled.div`
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  margin-top: 2rem;
`;

const Container = styled(Padding)`
  padding-top: 1em;
  margin-bottom: 2em;

  @media ${Device.Tablet} {
    padding-top: 2em;
  }
`;

const CandidateGridContainer = styled(Container)`
  background-color: #f6f6f6;
  padding-top: 0em;
`;
