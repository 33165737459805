import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import styled from 'styled-components';
import AgeRangeSlider from './AgeRangeSlider';
import { useTranslation } from 'react-i18next';
import type IElectionAllianceUnion from 'src/common/types/IElectionAllianceUnion';
import { useElectionAllianceUnions } from 'src/common/hooks/useElectionAllianceUnions';
import type { IElectionGroup } from 'src/common/types/IElectionGroup';

interface Props {
  selectedElectionGroup?: IElectionGroup;
  onSearchTermChanged: (searchTerm: string | null) => void;
  onCitySelected: (city: string | null) => void;
  onAllianceUnionSelected: (allianceUnion: IElectionAllianceUnion | null) => void;
  onAgeRangeChanged: (minAge: number, maxAge: number) => void;
  ageRange: [number, number];
  cities: string[];
}

export default function CandidateFilter(props: Props) {
  const { t } = useTranslation();
  const { isLoading: isLoadingAllianceUnions, data: alliances } = useElectionAllianceUnions(props.selectedElectionGroup?.elec_group_id);
  return (
    <form className="filtering-form" noValidate autoComplete="off">
      <Grid container direction="column" spacing={3}>
        <Grid item xs={12}>
          <TextField
            name="NameOrNumberSearch"
            fullWidth={true}
            label={t('candidategallery.filters.search_placeholder')}
            onChange={(event) => props.onSearchTermChanged(event.target.value)}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <IndentedLabel htmlFor="NameOrNumberSearch">
            {t('candidategallery.filters.search_label')}
          </IndentedLabel>
        </Grid>
        <Grid item xs={12}>
          {props.cities && (
            <>
              <Autocomplete
                className="city-select" 
                onChange={(event, newValue) => props.onCitySelected(newValue)}
                options={props.cities}
                getOptionLabel={(city) => city}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('candidategallery.filters.city_placeholder')}
                    variant="outlined"
                  />
                )}
              />
              <IndentedLabel htmlFor="city-select">
                {t('candidategallery.filters.city_label')}
              </IndentedLabel>
            </>
          )}
        </Grid>
        <Grid item xs={12}>
          {isLoadingAllianceUnions && <CircularProgress />}
          {alliances && (
            <>
              <Autocomplete<IElectionAllianceUnion>
                className="alliance-select"
                onChange={(event, newValue) => props.onAllianceUnionSelected(newValue)}
                options={alliances}
                getOptionLabel={(alliance) => alliance.name}
                getOptionSelected={(option, value) => option.name === value.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('candidategallery.filters.union_placeholder')}
                    variant="outlined"
                  />
                )}
              />
              <IndentedLabel htmlFor="alliance-select">
                {t('candidategallery.filters.union_label')}
              </IndentedLabel>
            </>
          )}
        </Grid>
        <Grid item xs={12}>
          <Label>{t('candidategallery.filters.age_label')}</Label>
          <AgeRangeSlider value={props.ageRange} onChange={props.onAgeRangeChanged} />
        </Grid>
      </Grid>
    </form>
  );
}

const Label = styled.label`
  display: block;
  margin-bottom: 0.3rem;
`;

const IndentedLabel = styled(Label)`
  padding-left: 0.8rem;
`;
