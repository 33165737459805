import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';
import { useElectionInformation } from '../hooks/useElectionInformation';
import type { IEcontact } from '../types/IElectionInformation';
import { useParams } from 'react-router-dom';
import { useTheme } from '@material-ui/core'
import { getCurrentPrimaryColor } from 'src/common/utilities/themeHelper';

export default function SocialMediaLinks() {
  const params = useParams<{uuid:string, electionGroup: string; }>();
  const theme = useTheme();
  const { data } = useElectionInformation(params.uuid);
  const econtactMap = data ? toMap(data.econtact_list) : new Map();

  return (
    <div className="election-social-media-links">
      {econtactMap.has("facebook") && (
        <a href={econtactMap.get("facebook")} target="_blank" rel="noreferrer" style={{ color: getCurrentPrimaryColor(theme) }}>
          <FacebookIcon />
        </a>
      )}
      {econtactMap.has("twitter") && (
        <a href={econtactMap.get("twitter")} target="_blank" rel="noreferrer" style={{ color: getCurrentPrimaryColor(theme) }}>
          <TwitterIcon />
        </a>
      )}
      {econtactMap.has("instagram") && (
        <a href={econtactMap.get("instagram")} target="_blank" rel="noreferrer" style={{ color: getCurrentPrimaryColor(theme) }}>
          <InstagramIcon />
        </a>
      )}
      {econtactMap.has("youtube") && (
        <a className="election-youtube" href={econtactMap.get("youtube")}  target="_blank" rel="noreferrer" style={{ color: getCurrentPrimaryColor(theme) }}>
          <YouTubeIcon />
        </a>
      )}
    </div>
  );
}

function toMap(econtacts?: IEcontact[]): Map<string, string | null> {
  if (econtacts) {
    return new Map(
      econtacts
        .filter((ec) => ec.econtact_type && ec.econtact_contact)
        .map((ec) => [ec.econtact_type?.toLocaleLowerCase(), ec.econtact_contact?.toLowerCase()] as [string, string]),
    );
  }
  return new Map();
}
