import React from 'react';
import styled from 'styled-components';
import { Device } from '../../common/utilities/devices';
import { useTheme } from '@material-ui/core'
import { getCurrentPrimaryColor } from '../../common/utilities/themeHelper';

export default function NoMatch()
{
  const theme = useTheme();

    return (
      <PageContainer style={{backgroundColor: getCurrentPrimaryColor(theme)}} >
        {/* <Container style={{mixBlendMode: "difference"}}> */}
        <Container style={{color: 'white'}}>
          <section>
            {"Sivua ei löydy"}          
          </section>
          <section>
            {"Page not found"}          
          </section>
          <section>
            {"Sidan kan inte hittas"}          
          </section>
        </Container>
      </PageContainer>
    );
}

const PageContainer = styled.div`
  width: 100%;  
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  `;

const Container = styled.div`
  
  font-weight: lighter;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;

  font-size: 2rem;
  margin-bottom: 0.3rem;

  @media ${Device.Tablet} {
    padding-top: 2rem;
    padding-bottom: 2rem;
    font-size: 3rem;
  }

  @media ${Device.Laptop} {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    font-size: 5rem;
  }
`;
