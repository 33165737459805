enum DeviceSize {
  MobileS = '320px',
  MobileM = '375px',
  MobileL = '425px',
  Tablet = '768px',
  Laptop = '1400px',
  Desktop = '2560px',
}

export const Device = {
  MobileS: `(min-width: ${DeviceSize.MobileS})`,
  MobileM: `(min-width: ${DeviceSize.MobileM})`,
  MobileL: `(min-width: ${DeviceSize.MobileL})`,
  Tablet: `(min-width: ${DeviceSize.Tablet})`,
  Laptop: `(min-width: ${DeviceSize.Laptop})`,
  Desktop: `(min-width: ${DeviceSize.Desktop})`,
};
