import React from 'react';
import styled from 'styled-components';
import TenantLogoLink from './TenantLogoLink';
import ElectionExternalLink from '../../common/components/ElectionExternalLink';
import LanguageSwitcher from './LanguageSwitcher';
import SocialMediaLinks from '../../common/components/SocialMediaLinks';
import Padding from '../../common/components/Padding';
import { Device } from 'src/common/utilities/devices';
import ThemeSwitcher from './ThemeSwitcher';

const Container = styled(Padding)`
  width: 100%;
  height: 5em;
  position: sticky;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1.5em;
`;

const IfDesktop = styled.div`
  display: none;

  @media ${Device.Laptop} {
    display: inline-block;
  }
`;

export default function TopBar() {
  return (
    <Container>
      <TenantLogoLink />
      <IfDesktop>
        <ElectionExternalLink />
      </IfDesktop>
      {/* <ThemeSwitcher /> */}
      <LanguageSwitcher />
      <IfDesktop>
        <SocialMediaLinks />
      </IfDesktop>
    </Container>
  );
}
