import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import type ICandidate from 'src/common/types/ICandidate';
import styled from 'styled-components';
import { useTheme } from '@material-ui/core'
import { getCurrentPrimaryColor } from 'src/common/utilities/themeHelper';

interface Props {
  candidate: ICandidate;
}

export default function CandidateInformation({ candidate }: Props) {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Grid className="candidate-info" container direction="column" spacing={3}>
      <Grid item>
        <div className="candidate-name"  style={{ color: getCurrentPrimaryColor(theme) }}>{`${candidate.candidate_calling_name ?? ''} ${
          candidate.candidate_last_name ?? ''
        }`}</div>
      </Grid>
      <Grid item>
        <InfoLabel>{t('candidategallery.candidate.occupation_label')}</InfoLabel>
        <InfoContent>{candidate.profession_text}</InfoContent>
      </Grid>
      <Grid item>
        <InfoLabel>{t('candidategallery.candidate.city_label')}</InfoLabel>
        <InfoContent>{candidate.locality_text}</InfoContent>
      </Grid>
      <Grid item>
        <InfoLabel>{t('candidategallery.candidate.union_label')}</InfoLabel>
        <InfoContent>{candidate.assoc_text}</InfoContent>
      </Grid>
      <Grid item>
        <InfoLabel>{t('candidategallery.candidate.description_label')}</InfoLabel>
        <InfoContent>{candidate.candidate_descr}</InfoContent>
      </Grid>
      <Grid item>
        <span className="union-name"  style={{ color: getCurrentPrimaryColor(theme) }}>{candidate.elec_union_name}</span>
        {' - '}
        <span className="union-slogan">{candidate.elec_union_slogan}</span>
      </Grid>
    </Grid>
  );
}

const InfoLabel = styled.label`
  display: block;
`;

const InfoContent = styled.span`
  display: block;
`;
