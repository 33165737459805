import type { PaletteType, Theme } from "@material-ui/core";

/**
 * Returns current primary color based on selected type (dark, light, main)
 * @param theme Theme
 * @returns color Color
 */
export function getCurrentPrimaryColor(theme :Theme)
{
    if ( theme.palette )
        return theme.palette.type === 'light' ? theme.palette.primary.light : (theme.palette.type === 'dark' ? theme.palette.primary.dark : theme.palette.primary.main);
    else
        return "#000000";
}

/**
 * Returns current secondary color based on selected type (dark, light, main)
 * @param theme Theme
 * @returns color Color
 */
 export function getCurrentSecondaryColor(theme :Theme)
 {
     if ( theme.palette )
         return theme.palette.type === 'light' ? theme.palette.secondary.light : (theme.palette.type === 'dark' ? theme.palette.secondary.dark : theme.palette.secondary.main);
     else
         return "#000000";
 }

/**
 * Returns selected theme type (dark, light)
 * @param theme Theme
 * @returns type string
 */
 export function getCurrentThemeType(theme :Theme)
 {
    return theme.palette.type;
 }