import type ICandidate from 'src/common/types/ICandidate';
import styled from 'styled-components';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { Device } from 'src/common/utilities/devices';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core'
import { getCurrentPrimaryColor } from 'src/common/utilities/themeHelper';

interface Props {
  candidate: ICandidate;
}

export default function CandidatePhotoAndNumber({ candidate }: Props) {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <>
      <Square>
        {candidate.candidate_image_url ? (
          <CandidatePhoto
            src={candidate.candidate_image_url}
            alt={t('candidategallery.candidate.photo_alt')}
          />
        ) : (
          <PlaceholderPhoto>
            <AccountCircleIcon />
          </PlaceholderPhoto>
        )}
        <CandidateNumber className="candidate-number" style={{ backgroundColor: getCurrentPrimaryColor(theme) }}>{candidate.candidate_number}</CandidateNumber>
      </Square>
    </>
  );
}

const Square = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 15px;

  @media ${Device.Tablet} {
    width: 400px;
  }

  &::after {
    content: block;
    padding-bottom: 100%;
  }
`;

const CandidatePhoto = styled.img`
  overflow: hidden;
  width: 100%;
  height: 100%;
`;

const PlaceholderPhoto = styled.div`
  svg {
    color: #f6f6f6;
    width: 100%;
    height: 100%;
  }
`;

const CandidateNumber = styled.div`
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;

  bottom: -30px;
  right: 20px;
`;
