import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { useTranslation } from 'react-i18next';
import { Link, useRouteMatch } from 'react-router-dom';

import type ICandidate from 'src/common/types/ICandidate';
import { Device } from 'src/common/utilities/devices';
import styled from 'styled-components';
import { candidateToPathSegment } from '../../common/utilities/urlHelpers';
import { useTheme } from '@material-ui/core'
import { getCurrentPrimaryColor } from 'src/common/utilities/themeHelper';
import { useConfiguration } from '../../common/hooks/useConfiguration';
import { AccountBox } from '@material-ui/icons';

interface Props {
  candidate: ICandidate;
}

export default function CandidateCard(props: Props) {
  // const match = useRouteMatch();  
  const match = useRouteMatch<{uuid:string, electionGroup: string; }>();

  const { data: config } = useConfiguration(match.params.uuid);

  return (
    <CardContainer className="candidate-card">
      <CardPaper elevation={2}>
        <CardLink className={config?.HideCandidateDetails ? "nolink" : ""}
          to={config?.HideCandidateDetails ? '#' :
            {
            pathname: `${match.url}/${candidateToPathSegment(props.candidate)}`,
            state: { candidate: props.candidate },
          }
        }
        >
          <Grid container spacing={2} direction="column" alignItems="center" justify="center">
            <Grid item container direction="column" alignItems="center" justify="flex-start">
              <Spacer />
              <ImageWithCandidateNumber
                imageUrl={props.candidate.candidate_image_url}
                number={props.candidate.candidate_number}
              />
              <CandidateName className="candidate-name">
                {props.candidate.candidate_calling_name} {props.candidate.candidate_last_name}
              </CandidateName>
              <div className="candidate-profession">{props.candidate.profession_text}</div>
              <div className="candidate-city">{props.candidate.locality_text}</div>
              <div className="candidate-association">{props.candidate.assoc_text}</div>
              <div className="candidate-union">{props.candidate.elec_union_name}</div>
              <Spacer />
            </Grid>
          </Grid>
        </CardLink>
      </CardPaper>
    </CardContainer>
  );
}

function ImageWithCandidateNumber(props: { imageUrl: string | undefined; number: number }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const match = useRouteMatch<{uuid:string, electionGroup: string; }>();
  const { data: config } = useConfiguration(match.params.uuid);
  
  return (
    <>
      {props.imageUrl ? (
        <RoundedImage className={config?.UseRectangeCandidateImages ? "rectangle-image" : ""}
          src={props.imageUrl}
          alt={t('candidategallery.candidate.photo_alt')}
        />
      ) : (
        <PlaceholderImage className="placeholder-image">
          {config?.UseRectangeCandidateImages ? <AccountBox /> : <AccountCircleIcon />}
        </PlaceholderImage>
      )}
      <CandidateNumberCircle className="candidate-number-container" style={{ backgroundColor: getCurrentPrimaryColor(theme) }}>
        {props.number}
      </CandidateNumberCircle>
    </>
  );
}

const CardLink = styled(Link)`
`;

const CardContainer = styled.div`
  max-width: calc(100%);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CardPaper = styled(Paper)`
  height: 370px;

  @media ${Device.Tablet} {
    height: 439px;
  }
`;

const Spacer = styled.div`
  visibility: none;
  height: 1.5rem;

  @media ${Device.Tablet} {
    height: 2.5rem;
  }
`;

const RoundedImage = styled.img`
  width: 170px;
  height: 170px;
  object-fit: contain;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
`;

const PlaceholderImage = styled.div`
  svg {
    margin: -20px;
    width: 210px;
    height: 210px;
  }
`;

const CandidateNumberCircle = styled.div`
  position: absolute;
  width: 46px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 130px;
  margin-top: 140px;

  @media ${Device.Tablet} {
    margin-left: 120px;
    margin-top: 155px;
  }
`;

const CandidateName = styled.span`
  display: block;
  margin-top: 1rem;
`;
