import styled from 'styled-components';
import { Device } from 'src/common/utilities/devices';

export default styled.div`
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
  height: 100%;
  width: 100%;

  padding-left: 15px;
  padding-right: 15px;

  @media ${Device.Tablet} {
    padding-left: 25px;
    padding-right: 25px;
  }

  @media ${Device.Laptop} {
    padding-left: 50px;
    padding-right: 50px;
  }
`;
