import { format, isValid } from 'date-fns';

export const formatDateForDisplay = (d?: Date): string =>
  d && isValid(d) ? format(d, 'd.M.yyyy') : '-';

export const formatDateTimeForDisplay = (d?: Date): string =>
  d && isValid(d) ? format(d, 'd.M.yyyy HH:mm') : '-';

export const formatDateRangeForDisplay = (d1?: Date, d2?: Date): string => {
  if (!d1 && !d2) {
    return '-';
  }
  const left = d1 ? formatDateForDisplay(d1) : ' ';
  const right = d2 ? formatDateForDisplay(d2) : ' ';
  return `${left} - ${right}`;
};


export const formatDateRangeForDisplayWithTime = (d1?: Date, d2?: Date): string => {
  if (!d1 && !d2) {
    return '-';
  }
  const left = d1 ? formatDateTimeForDisplay(d1) : ' ';
  const right = d2 ? formatDateTimeForDisplay(d2) : ' ';
  return `${left} - ${right}`;
};

export const formatDateRangeOnlyEndDateForDisplayWithTime = (d1?: Date, d2?: Date): string => {
  if (!d1 && !d2) {
    return '-';
  }
  const left = d1 ? formatDateForDisplay(d1) : ' ';
  const right = d2 ? formatDateTimeForDisplay(d2) : ' ';
  return `${left} - ${right}`;
};
