import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { createUrl } from './common/utilities/urlHelpers';
import i18next from 'i18next';
import type { ILanguageResourcesResponse } from './common/types/ILanguageResourcesResponse';

i18n.use(initReactI18next).init({
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  }
});

export async function initializeI18N() {
  const response = await fetch(createUrl('languageresources'));
  if (response.ok) {
    const data: ILanguageResourcesResponse = await response.json();
    for (const language in data) {
      i18next.addResourceBundle(language, "translation", data[language].translation, true, true);
    }
  }
}

export const languages = ['fi', 'sv', 'en'];

export const isLanguageValid = (language: string) => languages.includes(language);

export default i18n;