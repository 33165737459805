import FacebookIcon from '@material-ui/icons/Facebook';
import HomeIcon from '@material-ui/icons/Home';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import type ICandidate from 'src/common/types/ICandidate';
import styled from 'styled-components';
import { useTheme } from '@material-ui/core'
import { getCurrentPrimaryColor } from 'src/common/utilities/themeHelper';

interface Props {
  candidate: ICandidate;
}

export default function CandidateSocialMediaLinks({ candidate }: Props) {
  const theme = useTheme();  
  
  return (
    <Container className="candidate-social-media-links">
      {candidate.twitter_url && (
        <a href={candidate.twitter_url} target="_blank" rel="noreferrer">
          <TwitterIcon className="twitter" />
        </a>
      )}
      {candidate.instagram_url && (
        <a href={candidate.instagram_url} target="_blank" rel="noreferrer" style={{ color: getCurrentPrimaryColor(theme) }}>
          <InstagramIcon className="instagram" />
        </a>
      )}
      {candidate.linkedin_url && (
        <a href={candidate.linkedin_url} target="_blank" rel="noreferrer" style={{ color: getCurrentPrimaryColor(theme) }}>
          <LinkedInIcon className="linkedin" />
        </a>
      )}
      {candidate.facebook_url && (
        <a href={candidate.facebook_url} target="_blank" rel="noreferrer" style={{ color: getCurrentPrimaryColor(theme) }}>
          <FacebookIcon className="facebook" />
        </a>
      )}
      {candidate.homepage_url && (
        <a href={candidate.homepage_url} target="_blank" rel="noreferrer" style={{ backgroundColor: getCurrentPrimaryColor(theme) }}> 
          <HomeIcon className="homepage" />
        </a>
      )}
    </Container>
  );
}

const Container = styled.div`
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
`;
