import { CircularProgress, Grid } from '@material-ui/core';
import { NavigateBefore } from '@material-ui/icons';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import type ICandidate from 'src/common/types/ICandidate';
import Padding from 'src/common/components/Padding';
import { Device } from 'src/common/utilities/devices';
import {
  findCandidateByPathifiedString,
  findElectionGroupByPathifiedString,
} from 'src/common/utilities/urlHelpers';
import Routes from 'src/routes';
import styled from 'styled-components';
import CandidateInformation from './CandidateInformation';
import CandidatePhotoAndNumber from './CandidatePhotoAndNumber';
import CandidateSocialMediaLinks from './CandidateSocialMediaLinks';
import { useElectionGroups } from 'src/common/hooks/useElectionGroups';
import { useCandidates } from 'src/common/hooks/useCandidates';
import { useTheme } from '@material-ui/core'
import { getCurrentPrimaryColor } from 'src/common/utilities/themeHelper';

export default function Ehdokas() {
  const { t, i18n } = useTranslation();
  const params = useParams<{uuid:string, electionGroup: string; candidate: string }>();
  const { data: electionGroups } = useElectionGroups(params.uuid);
  const { data: candidates } = useCandidates(findElectionGroupByPathifiedString(params.electionGroup, electionGroups), params.uuid);
  const theme = useTheme();

  // Finally pick selected candidate from data
  // It's done this way because user might browse to candidates page directly using url
  // in which case electionGroups and candidates haven't been fetched yet
  const [selectedCandidate, setSelectedCandidate] = useState<ICandidate | null>(null);
  useEffect(() => {
    setSelectedCandidate(findCandidateByPathifiedString(params.candidate, candidates));
  }, [candidates, params.candidate]);

  return (
    <>
      {!selectedCandidate ? (
        <LoaderContainer>
          <CircularProgress />
        </LoaderContainer>
      ) : (
        <OuterContainer className="candidate-page">
          <Grid container spacing={2} direction="column" alignItems="flex-start">
            <Grid item>
              <ReturnLink className="return-link">
                <Link
                  style={{ color: getCurrentPrimaryColor(theme) }}
                  to={generatePath(Routes.ElectionGroup, {
                    uuid: params.uuid,
                    language: i18n.language,
                    electionGroup: params.electionGroup,
                  })}
                >
                  <NavigateBefore />
                  {t('candidategallery.back_to_results')}
                </Link>
              </ReturnLink>
            </Grid>
            <Grid item style={{width: "100%"}}>
              <Row>
                <Column>
                  <CandidatePhotoAndNumber candidate={selectedCandidate} />
                  <CandidateSocialMediaLinks candidate={selectedCandidate} />
                </Column>
                <Column>
                  <CandidateInformation candidate={selectedCandidate} />
                </Column>
              </Row>
            </Grid>
          </Grid>
        </OuterContainer>
      )}
    </>
  );
}

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

const OuterContainer = styled(Padding)`
  margin-left: auto;
  margin-right: auto;
  margin-top: 1.7rem;

  @media ${Device.Laptop} {
    width: 55%;
  }
`;

const Column = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: justify-content;
`;

const Row = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 2rem;

  flex-flow: row wrap;

  @media ${Device.Tablet} {
    flex-flow: row nowrap;
  }
`;

const ReturnLink = styled.div`
  a {
    text-decoration: none;
  }

  svg {
    vertical-align: middle;
  }
`;
