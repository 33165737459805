import { useQuery } from 'react-query';
import Queries from './queries';
import { createUrl } from '../utilities/urlHelpers';
import type IElectionAllianceUnion from '../types/IElectionAllianceUnion';

// TODO: NotFound?
async function GetElectionAllianceUnions(
  electionGroupId?: number,
): Promise<IElectionAllianceUnion[]> {
  const response = await fetch(createUrl(`elections/electiongroups/${electionGroupId}/associationunions`));
  if (response.ok) {
    const data: IElectionAllianceUnion[] = await response.json();
    return data;
  }
  return [];
}

export function useElectionAllianceUnions(electionGroupId?: number) {
  return useQuery(
    [Queries.AllianceUnion, electionGroupId],
    () => GetElectionAllianceUnions(electionGroupId),
    { enabled: !!electionGroupId },
  );
}
