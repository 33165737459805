import { useQuery } from 'react-query';
import { createUrl } from '../utilities/urlHelpers';
import type ICandidate from '../types/ICandidate';
import type { IElectionGroup } from '../types/IElectionGroup';
import Queries from './queries';

// TODO: NotFound?
async function GetCandidates(electionGroupId?: number, electionUuid?: string): Promise<ICandidate[]> {
  if (electionGroupId && electionUuid) {
    const response = await fetch(createUrl(`/elections/electiongroups/${electionGroupId}/${electionUuid}/candidates`));
    if (response.ok) {
      const data: {
        elec_election_id: number;
        elec_group_id: number;
        candidate_list: ICandidate[];
      } = await response.json();
      return data.candidate_list;
    }
  }
  return [];
}

export function useCandidates(electionGroup: IElectionGroup | null, electionUuid?: string) {
  return useQuery([Queries.Candidates, electionGroup?.elec_group_id, electionUuid], () => GetCandidates(electionGroup?.elec_group_id, electionUuid), {
    enabled: !!electionGroup,
  });
}
