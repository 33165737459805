enum Queries {
  ElectionInformation = 'electioninformation',
  ElectionGroups = 'electionGroups',
  Candidates = 'candidates',
  Cities = 'cities',
  AllianceUnion = 'allianceunions',
  ClientConfiguration = 'clientconfiguration'
}

export default Queries;
