import type ICandidate from 'src/common/types/ICandidate';
import { groupBy } from 'src/common/utilities/arrayHelpers';
import CandidateCard from 'src/features/ElectionGroup/CandidateCard';
import styled from 'styled-components';
import { getCurrentPrimaryColor } from 'src/common/utilities/themeHelper';
import { useTheme } from '@material-ui/core'
import { useTranslation } from 'react-i18next';
import { useConfiguration } from '../../common/hooks/useConfiguration';
import { useRouteMatch } from 'react-router-dom';

interface Props {
  candidates: ICandidate[];
}

export default function CandidateGrid(props: Props) {

  const { t } = useTranslation();
  const match = useRouteMatch<{uuid:string, electionGroup: string; }>();
  const { data: config } = useConfiguration(match.params.uuid);

  const theme = useTheme();
  const elec_unions = [...new Set(props.candidates.map(c => c.elec_union_name ? c.elec_union_name : null))];

  return (
    <Container>
      <div>
        {
          config?.CandidateGridGroupByUnion ?
          elec_unions.map((union : string | null) => {
            return (
              <div>
              <Label style={{backgroundColor: getCurrentPrimaryColor(theme)}}>{union ? union : t('candidategallery.candidate.no.union.name')}</Label>
              <FluidGrid>        
              {props.candidates.filter(c => c.elec_union_name === union).map((c, i) => <CandidateCard candidate={c} key={c.candidate_person_id} />)}
              </FluidGrid> 
            </div>)
          }) :
          <FluidGrid>        
          {props.candidates.map((c, i) => <CandidateCard candidate={c} key={c.candidate_person_id} />)}
          </FluidGrid>
        }
      </div>
    </Container>
  );
}

const Container = styled.div`
  margin-top: 2rem;
  background-color: #f6f6f6;

  a {
    text-decoration: none;
  }
`;

const Label = styled.h4`
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 0px;
  padding: 20px 0 20px 20px;
  color: white;
`

const FluidGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  margin: 0 auto;
  grid-gap: 20px;
  align-items: stretch;
  padding: 20px 20px;
`;