import { useQuery } from 'react-query';

import { createUrl } from '../utilities/urlHelpers';
import Queries from './queries';

import type { IClientConfiguration, IClientConfigurationResponse } from '../types/IClientConfiguration';

async function GetConfigurationInformation(uuid?: string): Promise<IClientConfiguration> {
  if ( uuid ){
    const result = await fetch(createUrl(`/configuration/${uuid}`));
    const data: IClientConfigurationResponse = await result.json();
    return {...data, CandidateGridGroupByUnion : parseBoolean(data.CandidateGridGroupByUnion), HideCandidateDetails : parseBoolean(data.HideCandidateDetails),
      UseRectangeCandidateImages : parseBoolean(data.UseRectangeCandidateImages)  };
  }
  else
    return {} as IClientConfiguration;
}

const parseBoolean = (d: string | undefined): boolean | undefined => d ? (d === 'true') : false;

export function useConfiguration(uuid?: string) {
  
  return useQuery([Queries.ClientConfiguration, uuid], () => GetConfigurationInformation(uuid), {
    //enabled: !!uuid
  });
}