import React from 'react';
import type { IElectionGroup } from '../../common/types/IElectionGroup';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useTranslation } from 'react-i18next';

interface Props {
  isLoading: boolean;
  electionGroups: IElectionGroup[] | undefined;
  onSelect: (selected: IElectionGroup | null) => void;
  value: IElectionGroup | null;
}

export default function ElectionGroupPicker(props: Props) {
  const { t } = useTranslation();
  return (
    <>
      {props.isLoading || !props.electionGroups ? (
        <CircularProgress />
      ) : (
        <Autocomplete<IElectionGroup>
          id="electionGroup-select"
          onChange={(event, newValue) => props.onSelect(newValue)}
          options={props.electionGroups}
          getOptionLabel={(electionGroup: IElectionGroup) => electionGroup.elec_group_name}
          getOptionSelected={(option, value) => option.elec_group_name === value.elec_group_name}
          value={props.value}
          renderInput={(params) => (
            <TextField {...params} label={t('candidategallery.electiongroup.input_placeholder')} variant="outlined" />
          )}
        />
      )}
    </>
  );
}
