import { IfMobileOrTablet } from 'src/common/components/DeviceSizeFilters';
import styled from 'styled-components';
import ElectionExternalLink from '../../common/components/ElectionExternalLink';
import SocialMediaLinks from '../../common/components/SocialMediaLinks';

const Container = styled.footer`
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .election-social-media-links {
    margin-bottom: 0.3rem;
  }
`;

export default function Footer() {
  return (
    <IfMobileOrTablet>
      <Container>
        <SocialMediaLinks />
        <ElectionExternalLink />
      </Container>
    </IfMobileOrTablet>
  );
}
