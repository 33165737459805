import React from 'react';
import TopBar from './TopBar';
import ElectionBanner from './ElectionBanner';

export default function Header() {
  return (
    <>
      <TopBar />
      <ElectionBanner />
    </>
  );
}
