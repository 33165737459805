import type ICandidateFilters from '../../common/types/ICandidateFilters';
import type ICandidate from '../../common/types/ICandidate';

export default function FilterCandidates(
  filters: ICandidateFilters,
  candidates: ICandidate[] | undefined,
): ICandidate[] {
  if (!candidates) {
    return [];
  }

  const filteredCandidates = candidates.filter(
    (c) =>
      searchTermMatches(
        filters.searchTerm,
        `${c.candidate_calling_name} ${c.candidate_last_name}`,
        c.candidate_number,
      ) &&
      cityMatches(filters.city, c.locality_text) &&
      allianceUnionMatches(filters.allianceUnion?.name, c.elec_union_name) &&
      ageMatches(filters.minAge, filters.maxAge, c.age),
  );
  return filteredCandidates.sort( (a,b) => a.candidate_number - b.candidate_number);
}

const includesCaseInsensitive = (s1: string, s2: string): boolean =>
  s1.toLowerCase().includes(s2.toLowerCase());

const searchTermMatches = (
  searchTerm: string | null,
  name: string,
  candidateNumber: number | null,
): boolean =>
  !searchTerm ||
  includesCaseInsensitive(candidateNumber?.toString() ?? '', searchTerm) ||
  includesCaseInsensitive(name, searchTerm);

const cityMatches = (filterCity: string | null, actualCity: string): boolean =>
  !filterCity || actualCity === filterCity;

const allianceUnionMatches = (
  filterAllianceUnion?: string,
  actualAllianceUnion?: string,
): boolean => !filterAllianceUnion || actualAllianceUnion === filterAllianceUnion;

const ageMatches = (minAge: number, maxAge: number, actualAge?: number): boolean => 
   !actualAge || (minAge <= actualAge && maxAge >= actualAge);
