import { useEffect, useRef, useState } from 'react';
import './App.scss';
import { generatePath, Redirect, Route, useHistory, useLocation } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import Header from './features/Header';
import ElectionGroup from './features/ElectionGroup';
import Ehdokas from './features/Candidate';
import { useTranslation } from 'react-i18next';
import { initializeI18N, isLanguageValid } from './i18n';
import Routes from 'src/routes';
import Footer from 'src/features/Footer';
import styled from 'styled-components';
import NoMatch from 'src/features/NoMatch';
import { ClientThemeProvider } from './common/components/ClientThemeProvider';

const queryClient = new QueryClient();

function App() {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const [hasLoadedTranslations, setHasLoadedTranslations] = useState(false);
  const [electionUuid, setElectionUuid] = useState<string>();

  useEffect(() => {
    document.title = t('candidategallery.header');
  }, [i18n.language, t, hasLoadedTranslations]);

  const previousSelectedLanguage = useRef(i18n.language);
  const previousUrlLanguage = useRef<string>();

  useEffect(() => {
    async function initialize() {
      await initializeI18N();
      setHasLoadedTranslations(true);
    }

    initialize();
  }, []);

  useEffect(() => {
    const currentUrlLanguage = location.pathname.split('/').find(isLanguageValid);
    if (!currentUrlLanguage) {
      return;
    }

    if (
      previousSelectedLanguage.current === i18n.language &&
      previousUrlLanguage.current !== currentUrlLanguage
    ) {
      // Language being changed by browsing to an url (/{newLang}/...)
      // Change i18n language
      i18n.changeLanguage(currentUrlLanguage);
      previousUrlLanguage.current = currentUrlLanguage;
    } else if (
      previousSelectedLanguage.current !== i18n.language &&
      previousUrlLanguage.current === currentUrlLanguage
    ) {
      // New language selected by changing i18n language (LanguageSwitcher component)
      // Update url
      history.replace(location.pathname.replace(currentUrlLanguage, i18n.language));
      previousSelectedLanguage.current = i18n.language;
    }
  }, [i18n.language, history, location, i18n]);

  // Get Election uuid from url
  useEffect( () => {
    const id = location.pathname.split('/').find(isUUID);    
    if ( id ){
      setElectionUuid(id);
    }
    else {
      // No correct uuid found -> use fake uuid so that empty page message can be shown with
      // customers colors
      setElectionUuid("-1");
    }
  }, [location, electionUuid]);

  return (
    <>
      {true ? (
          <QueryClientProvider client={queryClient}>
          <ClientThemeProvider uuid={electionUuid} hasLoadedInitData={hasLoadedTranslations}>
          <PageContainer>
            <ContentWrap>
              {/* <Switch> */}
               <Route
                  path={[Routes.Home, Routes.ElectionGroup]}>
                  <Header />
                </Route>
                <Route 
                  exact 
                  path={Routes.Root}
                  render={({match}) => {
                    if (isElectionIdValid(match.params.uuid)) {
                      return <Redirect to={generatePath(Routes.Home, {uuid: match.params.uuid, language: i18n.language ?? 'fi' })} />
                    }
                    return <Redirect to={Routes.NoMatch} />;
                  }}>
                  
                </Route>
                <Route
                  exact
                  path={[Routes.Home, Routes.ElectionGroup]}
                  render={({ match }) => {
                    if (isLanguageValid(match.params.language)) {
                      return <ElectionGroup />;
                    }
                    return <Redirect to={Routes.Root} />;
                  }}
                />
                <Route
                  exact
                  path={Routes.Candidate}
                  render={({ match }) => {
                    if (isLanguageValid(match.params.language)) {
                      return <Ehdokas />;
                    }
                    return (
                      <Redirect
                        to={generatePath(Routes.Candidate, {
                          uuid: match.params.uuid,
                          language: 'fi',
                          electionGroup: match.params.electionGroup,
                          candidate: match.params.candidate,
                        })}
                      />
                    );
                  }}
                />
                <Route
                  path={[Routes.NoMatch]}
                  render={({ match }) => {
                    return <NoMatch />
                  }}
                />
              {/* </Switch> */}
            </ContentWrap>
            <Route
              path={[Routes.Home, Routes.ElectionGroup]}>
              <Footer />
            </Route>
          </PageContainer>
          </ClientThemeProvider>
        </QueryClientProvider>
      ) : (
        ""
      )}
    </>
  );
}

const isElectionIdValid = (id: string) => id;

const isUUID = (uuid : string ) => {
  let s : string = "" + uuid;

  let result = s.match('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$');
  if (result === null) {
    return false;
  }
  return true;
}

const PageContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const ContentWrap = styled.div`
  flex-grow: 3;
`;

const ProgressInfoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
`;

const NotFoundContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default App;
