import { Grid } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useElectionInformation } from 'src/common/hooks/useElectionInformation';
import type { IElectionGroup } from 'src/common/types/IElectionGroup';
import { Device } from 'src/common/utilities/devices';
import styled from 'styled-components';
import {formatDateForDisplay, formatDateRangeForDisplay, formatDateRangeForDisplayWithTime, formatDateRangeOnlyEndDateForDisplayWithTime} from 'src/common/utilities/dateHelpers';
import { useParams } from 'react-router-dom';

const Container = styled.section`
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%),
    0px 1px 10px 0px rgb(0 0 0 / 12%);
  padding: 1.5rem;
  border-radius: 4px;

  margin-top: 1rem;
  margin-bottom: 1rem;
  
  @media ${Device.Tablet} {
    margin-left: 2rem;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  @media ${Device.Laptop} {
    margin-right: 2rem;
  }
`;

const Label = styled.label`
  display: block;
`;

interface Props {
  electionGroup: IElectionGroup;
}

export default function ElectionGroupCard({ electionGroup }: Props) {
  const { t } = useTranslation();
  const params = useParams<{uuid:string, electionGroup: string; }>();

  const { data: electionInformation } = useElectionInformation(params.uuid);
  return (
    <Container className="electionGroup-card">
      <Grid container direction="column" spacing={3}>
        <Grid item xs={12}>
          <span className="electionGroup-card-title">{t('candidategallery.electiongroup.header')}</span>
        </Grid>
        <Grid item xs={12}>
          <Label>{t('candidategallery.electiongroup.name_label')}</Label>
          {electionGroup.elec_group_name}
        </Grid>
        <Grid item xs={12}>
          <Label>{t('candidategallery.electiongroup.max_selected_count_label')}</Label>
          {electionGroup.max_elected_count_per_group}
        </Grid>
        { electionGroup?.e_advance_voting_used_yn ? 
          <Grid item xs={12}>
            <Label>{t('candidategallery.electiongroup.advance_evote_date_range_label')}</Label>
            {formatDateRangeForDisplayWithTime(electionGroup?.e_advance_voting_start, electionGroup?.e_advance_voting_end)}
          </Grid> : null
        }
        { electionGroup?.e_voting_used_yn ?
          <Grid item xs={12}>
            <Label>{t('candidategallery.electiongroup.evote_date_range_label')}</Label>
            {formatDateRangeForDisplayWithTime(electionGroup?.e_voting_start, electionGroup?.e_voting_end)}
          </Grid> : null
        }
        { electionGroup?.mail_voting_used_yn ?
          <Grid item xs={12}>
            <Label>{t('candidategallery.electiongroup.mail_vote_date_range_label')}</Label>
            {formatDateRangeOnlyEndDateForDisplayWithTime(electionGroup?.mail_voting_start, electionGroup?.mail_voting_end)}
          </Grid> : null
        }
        { electionGroup?.urn_election_used_yn ? 
          <Grid item xs={12}>
            <Label>{t('candidategallery.electiongroup.urn_vote_date_label')}</Label>
            {formatDateRangeForDisplay(electionGroup?.urn_election_start, electionGroup?.urn_election_end)}
          </Grid>
          : null
        }
        <Grid item xs={12}>
            <Label>{t('candidategallery.electiongroup.result_publish_date_label')}</Label>
            {formatDateForDisplay(electionInformation?.result_publication_date)}
          </Grid>
      </Grid>
    </Container>
  );
}
