import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { languages } from '../../i18n';
import { useTheme } from '@material-ui/core'
import { getCurrentPrimaryColor, getCurrentSecondaryColor } from 'src/common/utilities/themeHelper';

export default function LanguageSwitcher() {
  const { i18n } = useTranslation();
  const theme = useTheme();

  return (
    <div className="language-switcher">
      {languages.map((lang, index) => {
        return (
          <React.Fragment key={lang}>
            {index > 0 && ' | '}
            <LanguageButton
              className={i18n.language === lang ? 'selected' : ''}
              style={{ color: i18n.language === lang ? getCurrentPrimaryColor(theme) : getCurrentSecondaryColor(theme) }}
              onClick={() => i18n.changeLanguage(lang)}
            >
              {lang.toUpperCase()}
            </LanguageButton>
          </React.Fragment>
        );
      })}
    </div>
  );
}

const LanguageButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: none;
  display: inline;
  margin: 0;
  padding: 0;
`;
