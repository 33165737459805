import type { IElectionGroup, IElectionGroupResponse } from '../types/IElectionGroup';
import { useQuery } from 'react-query';
import Queries from './queries';
import { createUrl } from '../utilities/urlHelpers';
import { parseISO } from 'date-fns';

// TODO: NotFound?
async function GetElectionGroups(id?: string): Promise<IElectionGroup[]> {

  if (id) {
    const response = await fetch(createUrl(`/elections/electiongroups/${id}`));

    if (response.ok) {

      const data: IElectionGroupResponse[] = await response.json();

      return data.map(e => {
        return {
          ...e,
          e_advance_voting_start: parseDate(e.e_advance_voting_start),
          e_advance_voting_end: parseDate(e.e_advance_voting_end),
          e_voting_start: parseDate(e.e_voting_start),
          e_voting_end: parseDate(e.e_voting_end),
          urn_election_start: parseDate(e.urn_election_start),
          urn_election_end: parseDate(e.urn_election_end),
          result_publication_date: parseDate(e.result_publication_date),
          result_confirmation_date: parseDate(e.result_confirmation_date),
          e_advance_voting_used_yn: e.e_advance_voting_used_yn === "N" ? false : true,
          e_voting_used_yn : e.e_voting_used_yn === "N" ? false : true,
          urn_election_used_yn : e.urn_election_used_yn === "N" ? false : true,
          mail_voting_used_yn : e.mail_voting_used_yn === "N" ? false : true,
          mail_voting_start: parseDate(e.mail_voting_start),
          mail_voting_end: parseDate(e.mail_voting_end),        }
      });
    }
    return [];
  }
  return [];
}

const parseDate = (d: string | undefined): Date | undefined => d ? parseISO(d) : undefined;

export function useElectionGroups(id: string) {
  return useQuery([Queries.ElectionGroups, id], () => GetElectionGroups(id), {
    enabled: !!id
  });
}